import classNames from 'classnames';
import { GraphRow } from '../GraphRow/GraphRow';
import { Text } from '../Text/Text';
import './GraphXAxisRow.scss';

interface IPropsWithColumns extends IGraphXAxisRowColumns {
  align?: 'left' | 'center' | 'right';
  bold?: boolean;
  hideNarrowColumns?: boolean;
  ellipsis?: boolean;
  color?: 'primary' | 'secondary';
}

interface IPropsWithTicks extends IGraphXAxisRowTicks {
  hideEdgeTicks?: boolean;
}

export interface IGraphXAxisRowColumns {
  type: 'columns';
  columns: TGraphXAxisRowColumn[];
}

export type TGraphXAxisRowColumn = {
  label: string;
  normalizedX: number;
  normalizedWidth: number;
  className?: string;
};

export interface IGraphXAxisRowTicks {
  type: 'ticks';
  ticks: TGraphXAxisRowTick[];
}

export type TGraphXAxisRowTick = {
  label: string;
  normalizedX: number;
};

const DEFAULT_HEIGHT = 18;
const BOLD_HEIGHT = 24;

// We show ellipsis when columns are too short,
// but at a certain point we should just hide the column completely
// instead of showing one or two characters followed by an ellipsis.
const MINIMUM_NORMALIZED_COLUMN_WIDTH = 0.1;

export function GraphXAxisRow(props: IPropsWithColumns | IPropsWithTicks) {
  if (props.type === 'columns') {
    return <Columns {...props} />;
  }

  return <Ticks {...props} />;
}

function Columns(props: IPropsWithColumns) {
  const {
    columns,
    align = 'center',
    bold = false,
    hideNarrowColumns = false,
    ellipsis = false,
    color = 'secondary'
  } = props;

  const className = classNames('graph-x-axis-row__column', {
    'graph-x-axis-row__column--align-left': align === 'left',
    'graph-x-axis-row__column--align-right': align === 'right',
    'graph-x-axis-row__column--ellipsis': ellipsis === true
  });

  return (
    <GraphRow
      className="graph-x-axis-row"
      type="columns"
      height={bold ? BOLD_HEIGHT : DEFAULT_HEIGHT}
      columns={columns.map(column => ({
        normalizedX: column.normalizedX,
        normalizedWidth: column.normalizedWidth,
        content: (
          <Text
            as="div"
            size={bold ? '4' : '5'}
            weight={bold ? 'bold' : 'normal'}
            color={color}
            className={classNames(className, column.className)}
            hidden={hideNarrowColumns && column.normalizedWidth < MINIMUM_NORMALIZED_COLUMN_WIDTH}
          >
            {column.label}
          </Text>
        )
      }))}
    />
  );
}

function Ticks(props: IPropsWithTicks) {
  const { ticks, hideEdgeTicks = true } = props;

  return (
    <GraphRow
      className="graph-x-axis-row"
      type="ticks"
      height={DEFAULT_HEIGHT}
      ticks={ticks.map(tick => ({
        normalizedX: tick.normalizedX,
        content: (
          <Text as="div" size="5" color="secondary">
            {tick.label}
          </Text>
        )
      }))}
      hideEdgeTicks={hideEdgeTicks}
    />
  );
}
