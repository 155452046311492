import { useTranslate } from '../../lib/hooks/useTranslate';
import { IGradientStop } from '../GraphGradient/GraphGradient';
import { Text } from '../Text/Text';
import './GraphLegendNew.scss';
import { GraphLegendNew__Icon } from './GraphLegendNew__Icon';

export interface IGraphLegendNew {
  type: TGraphLegendNewType;
  label?: string;
  showLabel?: boolean;
}

export type TGraphLegendNewType =
  | 'plain-curve'
  | 'normal-curve'
  | 'missing-curve'
  | 'missing-bar'
  | 'dew-point-curve'
  | 'humidity-curve'
  | 'temperature-probability'
  | 'temperature-probability-warm'
  | 'temperature-probability-cold'
  | 'temperature-probability-twenty-one-day-forecast'
  | 'temperature-probability-twenty-one-day-forecast-only-warm'
  | 'temperature-probability-twenty-one-day-forecast-only-cold'
  | 'temperature-expected-twenty-one-day-forecast'
  | 'temperature-expected-twenty-one-day-forecast-only-warm'
  | 'temperature-expected-twenty-one-day-forecast-only-cold'
  | 'temperature-curve'
  | 'temperature-curve-warm'
  | 'temperature-curve-cold'
  | 'mean-temperature-curve'
  | 'measured-temperature-curve'
  | 'water-temperature-curve'
  | 'temperature-bars'
  | 'precipitation-bar'
  | 'precipitation-min-bar'
  | 'precipitation-max-bar'
  | 'precipitation-probability'
  | 'some-precipitation'
  | 'heavy-precipitation'
  | 'pressure-curve'
  | 'wind-probability'
  | 'wind-curve'
  | 'mean-wind-curve'
  | 'max-wind-curve'
  | 'wind-gust-curve'
  | 'wind-max-gust-curve'
  | 'snow-depth-bar'
  | 'air-quality-severity-low-curve'
  | 'air-quality-severity-moderate-curve'
  | 'air-quality-severity-severe-curve'
  | 'air-quality-severity-extreme-curve'
  | 'wave-height-curve'
  | 'sea-current-curve'
  | 'water-level-prediction'
  | 'water-level-forecast'
  | 'cloud-cover'
  | 'cloud-cover-high'
  | 'cloud-cover-middle'
  | 'cloud-cover-low'
  | 'cloud-cover-fog'
  | 'aurora-forecast';

interface IGraphLegendNewDefinition {
  appearance: TGraphLegendNewAppearance | TGraphLegendNewAppearance[];
  translationKey?: string;
  unit?: TGraphLegendNewUnit;
}

export type TGraphLegendNewAppearance =
  | { type: 'none' }
  | { type: 'line' }
  | { type: 'dashed-line' }
  | { type: 'two-lines' }
  | { type: 'diagonal-lines'; strokeWidth: number; strokeGap: number }
  | { type: 'bar' }
  | { type: 'two-bars' }
  | { type: 'brick-wall'; scale: number }
  | { type: 'diagonal-lines-with-gradient'; strokeWidth: number; strokeGap: number; gradientStops: IGradientStop[] };

type TGraphLegendNewUnit =
  | 'celsius'
  | 'millimeter'
  | 'centimeter'
  | 'meter'
  | 'centimetersPerSecond'
  | 'metersPerSecond'
  | 'percentage'
  | 'hectopascal';

const legends: Record<TGraphLegendNewType, IGraphLegendNewDefinition> = {
  'plain-curve': {
    appearance: { type: 'line' }
  },
  'normal-curve': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/normal'
  },
  'missing-curve': {
    appearance: { type: 'dashed-line' },
    translationKey: 'graphLegendNew/missingData'
  },
  'missing-bar': {
    appearance: { type: 'diagonal-lines', strokeWidth: 1.25, strokeGap: 1.25 },
    translationKey: 'graphLegendNew/missingData'
  },
  'dew-point-curve': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/dewPoint',
    unit: 'celsius'
  },
  'humidity-curve': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/humidity',
    unit: 'percentage'
  },
  'temperature-probability': {
    appearance: [
      { type: 'diagonal-lines', strokeWidth: 1.5, strokeGap: 2.5 },
      { type: 'diagonal-lines', strokeWidth: 1.5, strokeGap: 2.5 }
    ],
    translationKey: 'graphLegendNew/temperatureProbability'
  },
  'temperature-probability-warm': {
    appearance: { type: 'diagonal-lines', strokeWidth: 1.5, strokeGap: 2.5 },
    translationKey: 'graphLegendNew/temperatureProbability'
  },
  'temperature-probability-cold': {
    appearance: { type: 'diagonal-lines', strokeWidth: 1.5, strokeGap: 2.5 },
    translationKey: 'graphLegendNew/temperatureProbability'
  },
  'temperature-probability-twenty-one-day-forecast': {
    appearance: [
      { type: 'diagonal-lines', strokeWidth: 1.5, strokeGap: 2.5 },
      { type: 'diagonal-lines', strokeWidth: 1.5, strokeGap: 2.5 }
    ],
    translationKey: 'graphLegendNew/temperaturePossible'
  },
  'temperature-probability-twenty-one-day-forecast-only-warm': {
    appearance: [{ type: 'diagonal-lines', strokeWidth: 1.5, strokeGap: 2.5 }],
    translationKey: 'graphLegendNew/temperaturePossible'
  },
  'temperature-probability-twenty-one-day-forecast-only-cold': {
    appearance: [{ type: 'diagonal-lines', strokeWidth: 1.5, strokeGap: 2.5 }],
    translationKey: 'graphLegendNew/temperaturePossible'
  },
  'temperature-expected-twenty-one-day-forecast': {
    appearance: [{ type: 'bar' }, { type: 'bar' }],
    translationKey: 'graphLegendNew/expectedTemperature'
  },
  'temperature-expected-twenty-one-day-forecast-only-warm': {
    appearance: [{ type: 'bar' }],
    translationKey: 'graphLegendNew/expectedTemperature'
  },
  'temperature-expected-twenty-one-day-forecast-only-cold': {
    appearance: [{ type: 'bar' }],
    translationKey: 'graphLegendNew/expectedTemperature'
  },
  'temperature-curve': {
    appearance: { type: 'two-lines' },
    translationKey: 'graphLegendNew/temperature',
    unit: 'celsius'
  },
  'temperature-curve-warm': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/temperature',
    unit: 'celsius'
  },
  'temperature-curve-cold': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/temperature',
    unit: 'celsius'
  },
  'mean-temperature-curve': {
    appearance: { type: 'two-lines' },
    translationKey: 'graphLegendNew/meanTemperature',
    unit: 'celsius'
  },
  'measured-temperature-curve': {
    appearance: { type: 'two-lines' },
    translationKey: 'graphLegendNew/measured',
    unit: 'celsius'
  },
  'water-temperature-curve': {
    appearance: { type: 'dashed-line' },
    translationKey: 'graphLegendNew/waterTemperature',
    unit: 'celsius'
  },
  'temperature-bars': {
    appearance: { type: 'two-bars' },
    translationKey: 'graphLegendNew/temperature',
    unit: 'celsius'
  },
  'precipitation-bar': {
    appearance: { type: 'bar' },
    translationKey: 'graphLegendNew/precipitation',
    unit: 'millimeter'
  },
  'precipitation-min-bar': {
    appearance: { type: 'bar' },
    translationKey: 'graphLegendNew/precipitation',
    unit: 'millimeter'
  },
  'precipitation-max-bar': {
    appearance: { type: 'diagonal-lines', strokeWidth: 1.5, strokeGap: 2.5 },
    translationKey: 'graphLegendNew/precipitationMax',
    unit: 'millimeter'
  },
  'pressure-curve': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/pressure',
    unit: 'hectopascal'
  },
  'precipitation-probability': {
    appearance: { type: 'none' },
    translationKey: 'graphLegendNew/precipitationProbability'
  },
  'some-precipitation': {
    appearance: { type: 'bar' },
    translationKey: 'graphLegendNew/somePrecipitation'
  },
  'heavy-precipitation': {
    appearance: { type: 'bar' },
    translationKey: 'graphLegendNew/heavyPrecipitation'
  },
  'wind-probability': {
    appearance: { type: 'diagonal-lines', strokeWidth: 1.5, strokeGap: 2.5 },
    translationKey: 'graphLegendNew/windProbability'
  },
  'wind-curve': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/wind',
    unit: 'metersPerSecond'
  },
  'mean-wind-curve': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/windMeanSpeed',
    unit: 'metersPerSecond'
  },
  'max-wind-curve': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/windMaxSpeed',
    unit: 'metersPerSecond'
  },
  'wind-gust-curve': {
    appearance: { type: 'dashed-line' },
    translationKey: 'graphLegendNew/windGust',
    unit: 'metersPerSecond'
  },
  'wind-max-gust-curve': {
    appearance: { type: 'dashed-line' },
    translationKey: 'graphLegendNew/windMaxGust',
    unit: 'metersPerSecond'
  },
  'snow-depth-bar': {
    appearance: { type: 'bar' },
    translationKey: 'graphLegendNew/snowDepth',
    unit: 'centimeter'
  },
  'air-quality-severity-low-curve': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/airQualitySeverityLow'
  },
  'air-quality-severity-moderate-curve': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/airQualitySeverityModerate'
  },
  'air-quality-severity-severe-curve': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/airQualitySeveritySevere'
  },
  'air-quality-severity-extreme-curve': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/airQualitySeverityExtreme'
  },
  'wave-height-curve': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/waveHeight',
    unit: 'meter'
  },
  'sea-current-curve': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/seaCurrent',
    unit: 'centimetersPerSecond'
  },
  'water-level-prediction': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/waterLevelPrediction',
    unit: 'centimeter'
  },
  'water-level-forecast': {
    appearance: { type: 'line' },
    translationKey: 'graphLegendNew/waterLevelForecast',
    unit: 'centimeter'
  },
  'cloud-cover': {
    appearance: [
      { type: 'diagonal-lines', strokeWidth: 1, strokeGap: 2 },
      { type: 'diagonal-lines', strokeWidth: 1.5, strokeGap: 2.5 },
      { type: 'diagonal-lines', strokeWidth: 2, strokeGap: 3 },
      { type: 'brick-wall', scale: 0.7 }
    ],
    translationKey: 'graphLegendNew/cloudCover'
  },
  'cloud-cover-high': {
    appearance: { type: 'diagonal-lines', strokeWidth: 1, strokeGap: 2 },
    translationKey: 'graphLegendNew/cloudCoverHigh'
  },
  'cloud-cover-middle': {
    appearance: { type: 'diagonal-lines', strokeWidth: 1.5, strokeGap: 2.5 },
    translationKey: 'graphLegendNew/cloudCoverMiddle'
  },
  'cloud-cover-low': {
    appearance: { type: 'diagonal-lines', strokeWidth: 2, strokeGap: 3 },
    translationKey: 'graphLegendNew/cloudCoverLow'
  },
  'cloud-cover-fog': {
    appearance: { type: 'brick-wall', scale: 0.7 },
    translationKey: 'graphLegendNew/cloudCoverFog'
  },
  'aurora-forecast': {
    appearance: {
      type: 'diagonal-lines-with-gradient',
      strokeWidth: 0.5,
      strokeGap: 1,
      gradientStops: [
        { normalizedY: 0, className: 'graph-legend-new__gradient-start' },
        { normalizedY: 1, className: 'graph-legend-new__gradient-stop' }
      ]
    },
    translationKey: 'graphLegendNew/auroraForecast'
  }
};

const units: Record<TGraphLegendNewUnit, string> = {
  celsius: 'units/celsius/short',
  millimeter: 'units/millimeter/short',
  centimeter: 'units/centimeter/short',
  meter: 'units/meter/short',
  centimetersPerSecond: 'units/centimetersPerSecond/short',
  metersPerSecond: 'units/metersPerSecond/short',
  percentage: 'units/percent/short',
  hectopascal: 'units/hectopascal/short'
};

export function GraphLegendNew(props: IGraphLegendNew) {
  const { type, label, showLabel = true } = props;

  const translate = useTranslate();

  const legend = legends[type];

  function getLabel() {
    // Use the default translation if we didn't get a custom label
    let textLabel = label;
    if (textLabel == null && legend.translationKey != null) {
      textLabel = translate(legend.translationKey);
    }

    if (textLabel == null) {
      return '';
    }

    const unitLabel = legend.unit != null ? translate(units[legend.unit]) : undefined;
    if (unitLabel == null) {
      return textLabel;
    }

    return `${textLabel} ${unitLabel}`;
  }

  function getIcons() {
    let icons;

    if (isGraphLegendArray(legend.appearance)) {
      icons = legend.appearance.map((appearance, index) => (
        <svg className="graph-legend-new__icon" width="10" height="10" viewBox="0 0 10 10" data-id={index} key={index}>
          <GraphLegendNew__Icon appearance={appearance} />
        </svg>
      ));
    } else if (legend.appearance.type !== 'none') {
      icons = (
        <svg className="graph-legend-new__icon" width="10" height="10" viewBox="0 0 10 10">
          <GraphLegendNew__Icon appearance={legend.appearance} />
        </svg>
      );
    }

    return icons;
  }

  return (
    <Text
      color="secondary"
      as="div"
      size="5"
      className="graph-legend-new"
      data-type={type}
      data-testid="graph-legend-new"
    >
      {getIcons()}
      {showLabel && getLabel()}
    </Text>
  );
}

function isGraphLegendArray(
  appearance: TGraphLegendNewAppearance | TGraphLegendNewAppearance[]
): appearance is TGraphLegendNewAppearance[] {
  return Array.isArray(appearance) === true;
}
