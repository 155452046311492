interface IProps {
  id: string;
  backgroundClassName: string;
  lineClassName: string;
  scale?: number;
}

export function GraphPatternBrickWall(props: IProps) {
  const { id, backgroundClassName, lineClassName, scale = 1 } = props;

  // Scale the pattern so each horizonal line is 2 SVG units tall
  const defaultTransformScale = (1 / 12) * 2;

  // Scale based on the given scale
  const patternTransformScale = defaultTransformScale * scale;

  // This pattern was copied from Figma
  return (
    <pattern
      id={id}
      width="72"
      height="72"
      patternUnits="userSpaceOnUse"
      patternTransform={`scale(${patternTransformScale})`}
    >
      <rect className={backgroundClassName} x="0" y="0" width="72" height="72" />

      <rect className={lineClassName} x="-9" y="54" width="57" height="12" rx="6" />
      <rect className={lineClassName} x="-45" y="6" width="57" height="12" rx="6" />
      <rect className={lineClassName} x="60" y="54" width="57" height="12" rx="6" />
      <rect className={lineClassName} x="24" y="6" width="57" height="12" rx="6" />
      <rect className={lineClassName} x="42" y="30" width="57" height="12" rx="6" />
      <rect className={lineClassName} x="-27" y="30" width="57" height="12" rx="6" />
    </pattern>
  );
}
