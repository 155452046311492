export interface IGradientStop {
  className: string;
  normalizedY: number;
}

interface IProps {
  id: string;
  stops: IGradientStop[];
  gradientUnits?: 'userSpaceOnUse' | 'objectBoundingBox';
}

export function GraphGradient(props: IProps) {
  const { id, stops, gradientUnits = 'userSpaceOnUse' } = props;

  // This component assumes we get gradient stops starting from the bottom of the graph
  // or lower (normalizedY is <= 0) and increasing.
  // When rendering the linear gradient offsets we must start at the top, however.
  const reversedStops = stops.slice().reverse();

  return (
    <linearGradient id={id} x1="0" y1="0" x2="0" y2="100%" gradientUnits={gradientUnits} spreadMethod="pad">
      {reversedStops.map((stop, index) => (
        // SVG starts with y=0 at the top while our graph components assumes y=0 is at the bottom
        // of the graph so to get the correct offset value we must do `1 - normalizedY`.
        <stop key={index} className={stop.className} offset={1 - stop.normalizedY} stopOpacity="1" />
      ))}
    </linearGradient>
  );
}
