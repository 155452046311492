import classNames from 'classnames';
import { pixelValueToRem } from '../../lib/formatGraphics';
import './GraphRow.scss';

interface IPropsWithColumns {
  className?: string;
  type: 'columns';
  // If height is not defined we give the row a height of 100%
  height: number;
  columns: TGraphRowColumn[];
}

interface IPropsWithTicks {
  className?: string;
  type: 'ticks';
  height: number;
  ticks: TGraphRowTick[];
  /**
   * Hide ticks at the left and right edges.
   * Necessary when those ticks would be partially cropped by the scrollable
   * graph container due to its `overflow-x: scroll` style.
   * If the graph is not scrollable this can be safely set to `false`.
   * Defaults to `true`.
   */
  hideEdgeTicks?: boolean;
}

export type TGraphRowColumn = {
  normalizedX: number;
  normalizedWidth: number;
  content: React.ReactNode;
};

export type TGraphRowTick = {
  normalizedX: number;
  content: React.ReactNode;
};

export function GraphRow(props: IPropsWithTicks | IPropsWithColumns) {
  if (props.type === 'columns') {
    return <Columns {...props} />;
  }

  return <Ticks {...props} />;
}

function Columns(props: IPropsWithColumns) {
  const { className, columns, height } = props;

  return (
    <div className={classNames('graph-row', className)} style={{ height: height ? pixelValueToRem(height) : '100%' }}>
      {columns.map(column => (
        <div
          key={column.normalizedX}
          className="graph-row__column"
          style={{
            left: `${100 * column.normalizedX}%`,
            width: `${100 * column.normalizedWidth}%`
          }}
        >
          {column.content}
        </div>
      ))}
    </div>
  );
}

function Ticks(props: IPropsWithTicks) {
  const { className, ticks, height, hideEdgeTicks = true } = props;

  return (
    <div className={classNames('graph-row', className)} style={{ height: pixelValueToRem(height) }}>
      {ticks.map(tick => {
        // When the graph is scrollable we don't want to render ticks at the left or right edge
        // because they would be partially rendered outside the scrollable graph container
        // and get cropped due to the overflow style rule.
        if (hideEdgeTicks && (tick.normalizedX <= 0 || tick.normalizedX >= 1)) {
          return null;
        }

        return (
          <div
            key={tick.normalizedX}
            className="graph-row__tick"
            style={{
              left: `${100 * tick.normalizedX}%`
            }}
          >
            {tick.content}
          </div>
        );
      })}
    </div>
  );
}
