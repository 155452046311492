import { useId } from 'react';
import { GraphGradient } from '../GraphGradient/GraphGradient';
import { GraphPatternBrickWall } from '../GraphPatternBrickWall/GraphPatternBrickWall';
import { GraphPatternDiagonalLines } from '../GraphPatternDiagonalLines/GraphPatternDiagonalLines';
import { TGraphLegendNewAppearance } from './GraphLegendNew';

interface IProps {
  appearance: TGraphLegendNewAppearance;
}

export function GraphLegendNew__Icon(props: IProps) {
  const { appearance } = props;

  const patternId = useId();
  const gradientId = useId();

  if (appearance.type === 'line') {
    return <line className="graph-legend-new__line" x1="0" y1="50%" x2="100%" y2="50%" strokeWidth="20%" />;
  }

  if (appearance.type === 'dashed-line') {
    return (
      <>
        <line
          className="graph-legend-new__line"
          x1="10%"
          y1="50%"
          x2="30%"
          y2="50%"
          strokeWidth="20%"
          strokeLinecap="round"
        />

        <line
          className="graph-legend-new__line"
          x1="70%"
          y1="50%"
          x2="90%"
          y2="50%"
          strokeWidth="20%"
          strokeLinecap="round"
        />
      </>
    );
  }

  if (appearance.type === 'two-lines') {
    return (
      <>
        <line className="graph-legend-new__line" x1="0" y1="30%" x2="100%" y2="30%" strokeWidth="20%" />
        <line className="graph-legend-new__line" x1="0" y1="70%" x2="100%" y2="70%" strokeWidth="20%" />
      </>
    );
  }

  if (appearance.type === 'diagonal-lines') {
    return (
      <>
        <GraphPatternDiagonalLines
          id={patternId}
          backgroundClassName="graph-legend-new__background"
          lineClassName="graph-legend-new__foreground"
          strokeGap={appearance.strokeGap}
          strokeWidth={appearance.strokeWidth}
        />

        <rect x="5%" y="5%" width="90%" height="90%" fill={`url(#${patternId})`} />
      </>
    );
  }

  if (appearance.type === 'bar') {
    return <rect className="graph-legend-new__bar" x="5%" y="5%" width="90%" height="90%" />;
  }

  if (appearance.type === 'two-bars') {
    return (
      <>
        <rect className="graph-legend-new__bar" x="30%" y="0%" width="20%" height="100%" />
        <rect className="graph-legend-new__bar" x="70%" y="0%" width="20%" height="100%" />
      </>
    );
  }

  if (appearance.type === 'brick-wall') {
    return (
      <>
        <GraphPatternBrickWall
          id={patternId}
          backgroundClassName="graph-legend-new__background"
          lineClassName="graph-legend-new__foreground"
          scale={appearance.scale}
        />

        <rect x="5%" y="5%" width="90%" height="90%" fill={`url(#${patternId})`} />
      </>
    );
  }

  if (appearance.type === 'diagonal-lines-with-gradient') {
    const { strokeWidth, strokeGap, gradientStops } = appearance;

    return (
      <>
        <GraphGradient id={gradientId} stops={gradientStops} gradientUnits="objectBoundingBox" />
        <GraphPatternDiagonalLines
          id={patternId}
          backgroundClassName="graph-legend-new__background"
          lineClassName="graph-legend-new__foreground"
          strokeWidth={strokeWidth}
          strokeGap={strokeGap}
        />
        <rect
          x="5%"
          y="5%"
          width="90%"
          height="90%"
          fill={`url(#${gradientId})`}
          style={{ mixBlendMode: 'multiply' }}
        />
        <rect
          x="5%"
          y="5%"
          width="90%"
          height="90%"
          fill={`url(#${patternId})`}
          style={{ mixBlendMode: 'luminosity' }}
        />
      </>
    );
  }

  return null;
}
