import classNames from 'classnames';
import { GraphLegendNew, IGraphLegendNew } from '../GraphLegendNew/GraphLegendNew';
import './GraphLegendRow.scss';

interface IProps {
  size?: 'normal' | 'small';
  legends: IGraphLegendNew[];
}

export function GraphLegendRow(props: IProps) {
  const { size = 'normal', legends } = props;

  return (
    <div
      className={classNames('graph-legend-row', {
        'graph-legend-row--small': size === 'small'
      })}
    >
      {legends.map((legend, index) => (
        <div key={index} className="graph-legend-row__legend">
          <GraphLegendNew {...legend} />
        </div>
      ))}
    </div>
  );
}
