interface IProps {
  id: string;
  backgroundClassName: string;
  lineClassName: string;
  strokeWidth?: number;
  strokeGap?: number;
}

export function GraphPatternDiagonalLines(props: IProps) {
  const { id, backgroundClassName, lineClassName, strokeWidth = 1, strokeGap = 2 } = props;

  const size = strokeGap + strokeWidth;

  return (
    <pattern id={id} width={size} height={size} patternUnits="userSpaceOnUse" patternTransform="rotate(-45 50 50)">
      <rect className={backgroundClassName} x="0" y="0" width={size} height={size} />
      <line className={lineClassName} x1="0" y1={size / 2} x2={size} y2={size / 2} strokeWidth={strokeWidth} />
    </pattern>
  );
}
